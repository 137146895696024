import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { defaultPageTransition, defaultTransition } from '../utilities/animationHelpers'
import { motion } from 'framer-motion'

import Img from 'react-image'
import Spinner from '../components/Spinner'

const variants = {
  hidden: {
    opacity: 0,
    y: 50
  },
  enter: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {...defaultTransition, delay: 0.25 + (custom/40)}
  })
}


const PageLink = ({ img, number, slug }) => {
  return (
    <motion.div
      style={{
        flex: '0 1 25%',
      }}
      className='flex justify-center my-6 mx-4'
      variants={variants}
      initial='hidden'
      animate='enter'
      custom={number}
    >
      <TransitionLink
        {...defaultPageTransition}
        to={`${slug}page-${number}`}
        className='inline w-6/12'
        style={{ minWidth: '175px' }}
      >
        <Img 
          src={img} 
          loader={<Spinner />}
          alt='' 
        />
        <p className='mt-2 underline'>page {number}</p>
      </TransitionLink>
    </motion.div>
  )
}

export default PageLink
