import React from 'react'
import { graphql, navigate } from 'gatsby'
import SEO from '../components/seo'
import PageLink from '../components/PageLink'
import { TransitionState } from 'gatsby-plugin-transition-link'
import { motion } from 'framer-motion'
import { defaultTransition } from '../utilities/animationHelpers'

const variants = {
  hidden: {
    opacity: 0,
    x: 0
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      ...defaultTransition,
      delay: 0.15
    }
  },
  exit: {
    opacity: 0,
    x: -100,
    transition: {...defaultTransition}
  }
}

const BookIndex = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter
  let pages = data.markdownRemark.frontmatter.pages
  const slug = data.markdownRemark.fields.slug

  const pageLinks = () =>
    pages.map((page, i) => (
      <PageLink key={i} img={page.textimage} number={i + 1} slug={slug} />
    ))

  return (
    <div>
      <SEO title={`${title} | Index`} />
      <TransitionState>
        {({ transitionStatus, entry }) => {
          return (
            <motion.div
              variants={variants}
              initial='hidden'
              animate={
                ['entering', 'entered', 'POP'].includes(transitionStatus)
                  ? 'enter'
                  : 'exit'
              }
              className='p-12 text-white'
            >
              <h1 className='lg:text-6xl text-5xl text-center'>
                Index of {title}
              </h1>
              <div className='flex flex-wrap justify-center text-2xl'>
                {pageLinks()}
              </div>
            </motion.div>
          )
        }}
      </TransitionState>
    </div>
  )
}

export default BookIndex

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        pages {
          content
          bgimage
          textimage
        }
      }
    }
  }
`
